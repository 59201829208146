import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const dayjs = require("dayjs");

function CardPayment({ id, amount, date }) {
  return (
    <Col className="manual-payment-element">
      <Row className="align-items-center">
        <Col lg={9}>
          <h5>${amount}</h5>
        </Col>
        <Col lg={3}>
          <h6>{dayjs(date).format("DD-MMM-YYYY")}</h6>
        </Col>
      </Row>
    </Col>
  );
}

export default CardPayment;
