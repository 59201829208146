import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";

function NewResidence() {
  const [address, setAddress] = useState("");

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const handleAddressChange = (evt) => {
    setAddress(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/residences/`,
        data: {
          address: address,
          account: state.account,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/residences");
    } catch (error) {
      toast(error.response.data.message)
      console.log(error);
    }
  };
  return (
    <Col className="new-residence">
      <Col>
        <h4>Nueva residencia</h4>
        <ToastContainer />
      </Col>
      <Col className="new-residence-form">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Dirección de la residencia</Form.Label>
            <Form.Control type="text" onChange={handleAddressChange} />
          </Form.Group>
          <Form.Group>
            <Button variant="info" onClick={handleSubmit}>
              Guardar
            </Button>
            <Button variant="danger" onClick={() => navigate("/residences")}>
              Cancelar
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Col>
  );
}

export default NewResidence;
