import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
const dayjs = require("dayjs");

function Ticket({
  id,
  title,
  description,
  date,
  userName,
  accountName,
  status,
  supportName,
  supportClosed,
  closed_at,
}) {
  return (
    <Col className={`ticket-element-${status} mb-3`}>
      <Col>
        <Row>
          <Col>
            <Col>{dayjs(date).format("DD-MMM-YYYY")}</Col>
            {status === "closed" ? (
              <Col>{dayjs(closed_at).format("DD-MMM-YYYY")}</Col>
            ) : (
              <></>
            )}
            
          </Col>
          <Col>
            <Col className="right">{supportName}</Col>
            <Col className="right">{accountName}</Col>
          </Col>
        </Row>
      </Col>
      <Col className="mt-2">
        <NavLink to={`/ticket-details/${id}`}>
          <h4 className="mb-2">{title}</h4>
        </NavLink>
        <p>{description}</p>
        {supportClosed ? <Badge bg="success">Reparado</Badge> : <></>}
      </Col>
      <Col>{userName}</Col>
    </Col>
  );
}

export default Ticket;
