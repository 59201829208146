import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import AuthContext from "../../Context/AuthContext";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { FaMoneyBillAlt, FaPlusCircle, FaCreditCard  } from "react-icons/fa";
import ResidenceUser from "../Components/ResidenceUser";

function ResidenceDetails() {
  const [residence, setResidence] = useState({});
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/residences/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setResidence(result.data.residence);

      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/residences/residence/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setUsers(response.data.users);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="residence-details">
        <Col className="residence-address center">
          <h4>{residence.address}</h4>
        </Col>
        <Col className="options-panel center">
          <FaCreditCard  onClick={() => navigate(`/card-payments/${rid}`)} />
          <FaMoneyBillAlt onClick={() => navigate(`/manual-payments/${rid}`)} />
          <FaPlusCircle onClick={() => navigate(`/new-user/${rid}`)} />
        </Col>
        <Col className="residence-users">
          <h5 className="mb-3 mt-3">Residentes</h5>
          {users.map((item) => (
            <ResidenceUser
              key={item._id}
              id={item._id}
              firstName={item.firstName}
              lastName={item.lastName}
            />
          ))}
        </Col>
      </Col>
    );
  }
}

export default ResidenceDetails;
