import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";

function NewTicket() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [account, setAccount] = useState("");
  const [file, setFile] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setAccounts(response.data.accounts);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleTitleChange = (evt) => {
    setTitle(evt.target.value);
  };

  const handleDescriptionChange = (evt) => {
    setDescription(evt.target.value);
  };

  const handleAccountChange = (evt) => {
    setAccount(evt.target.value);
  };

  const handleFileChange = (evt) => {
    setFile(evt.target.files[0]);
  };

  const handleSubmitRoot = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/`,
        data: {
          title: title,
          description: description,
          account: account,
          userId: state.userId,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      toast(`Ticket creado con éxito`);
      setTimeout(() => {
        navigate("/tickets");
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitAdmin = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("account", state.account);
    formData.append("userId", state.userId);

    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/`,
        data: formData,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      toast(`Ticket creado con éxito`);
      setTimeout(() => {
        navigate("/tickets");
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="new-ticket">
        <ToastContainer />
        <Col>
          <h4>Nuevo ticket</h4>
        </Col>
        <Col className="new-ticket-form">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Título del ticket"
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Descripción del ticket"
                onChange={handleDescriptionChange}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Archivo adjunto (opcional)</Form.Label>
              <Form.Control
                type="file"
                name="evidence"
                onChange={handleFileChange}
              />
            </Form.Group>
            {state.role === "root" ? (
              <Form.Group className="mb-3">
                <Form.Label>Fraccionamiento</Form.Label>
                <Form.Select onChange={handleAccountChange}>
                  <option key={""} value={""}>
                    Seleccionar fraccionamiento
                  </option>
                  {accounts.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : (
              <></>
            )}

            <Form.Group>
              {state.role === "root" ? (
                <Button variant="info" onClick={handleSubmitRoot}>
                  Crear ticket
                </Button>
              ) : state.role === "admin" ? (
                <Button variant="info" onClick={handleSubmitAdmin}>
                  Crear ticket
                </Button>
              ) : (
                <></>
              )}
              <Button variant="danger" onClick={() => navigate("/tickets")}>
                Cancelar
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Col>
    );
  }
}

export default NewTicket;
