import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
const dayjs = require("dayjs");

function ManualPayment({ id, amount, date, deletePayment, rid }) {
  const navigate = useNavigate();

  return (
    <Col className="manual-payment-element">
      <Row className="align-items-center">
        <Col lg={5}>
          <h5>${amount}</h5>
        </Col>
        <Col lg={4}>
          <h6>{dayjs(date).format("DD-MMM-YYYY")}</h6>
        </Col>
        <Col lg={3} className="right">
          <Button
            size="sm"
            onClick={() =>
              navigate("/edit-manual-payment", { state: { id: id, rid: rid } })
            }
            variant="info"
            style={{ marginRight: 10 }}
          >
            Editar
          </Button>
          <Button size="sm" onClick={() => deletePayment(id)} variant="danger">
            Eliminar
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default ManualPayment;
