import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import CardPayment from "../Components/CardPayment";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";

function CardPayments() {
  const { state } = useContext(AuthContext);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let { rid } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/cardPayments/residence/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setPayments(response.data.cardPayments);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    if (payments.length > 0) {
      return (
        <>
          <Col className="manual-payments">
            <Col className="manual-payments-header">
              <Row className="align-items-center">
                <Col>
                  <ToastContainer />
                  <h4>Pagos con tarjeta</h4>
                </Col>
              </Row>
            </Col>
            <Col className="manual-payments-content">
              {payments.map((item) => (
                <CardPayment
                  amount={item.amount}
                  date={item.created_at}
                  id={item._id}
                  key={item._id}
                />
              ))}
            </Col>
          </Col>
          <Button
            variant="secondary"
            className="mt-3"
            onClick={() => navigate(`/residence-details/${rid}`)}
          >
            Regresar
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Col className="manual-payments">
            <Col className="manual-payments-header">
              <Row className="align-items-center">
                <Col>
                  <ToastContainer />
                  <h4>Pagos con tarjeta</h4>
                </Col>
              </Row>
            </Col>
            <Col className="manual-payments-content center">
              <h4>No hay pagos registrados para esta residencia</h4>
            </Col>
          </Col>
          <Button
            variant="secondary"
            className="mt-3"
            onClick={() => navigate(`/residence-details/${rid}`)}
          >
            Regresar
          </Button>
        </>
      );
    }
  }
}

export default CardPayments;
