import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import LedgerEvent from "../Components/LedgerEvent";

function TimeLineReport() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/ledger/account/${result.data.report.account_id}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
        data: {
          start_date: result.data.report.start_date,
          end_date: result.data.report.end_date,
        },
      });
      setEvents(response.data.logs);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="timeline-report center">
        {events.map((item) => (
          <LedgerEvent
            firstName={item.user_firstName}
            lastName={item.user_lastName}
            created={item.created_at}
            type={item.event_type}
            visitorName={item.visitorName}
            name={item.name}
            amount={item.amount}
            residence={item.residence}
            amenity={item.amenity}
            key={item._id}
          />
        ))}
      </Col>
    );
  }
}

export default TimeLineReport;
