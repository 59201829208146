import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Ticket from "../Components/Ticket";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

function Tickets() {
  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      if (state.role === "admin") {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/tickets/account/${state.account}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setTickets(response.data.tickets);
        setCount(response.data.count);
        setIsLoading(false);
      } else if (state.role === "root") {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/tickets`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setTickets(response.data.tickets);
        setCount(response.data.count);
        setIsLoading(false);
      } else if (state.role === "support") {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/tickets/technician/${state.userId}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setTickets(response.data.tickets);
        setCount(response.data.count);
        setIsLoading(false);
      } else {
        setTickets([]);
      }
    }
    fetch();
  }, []);

  if (isLoading) {
    return <Spinner />;
  } else {
    if (count > 0) {
      if (state.role === "root") {
        const pendingTickets = tickets.filter(
          (item) => item.status === "pending"
        );
        const openTickets = tickets.filter((item) => item.status === "open");
        const closedTickets = tickets.filter(
          (item) => item.status === "closed"
        );

        return (
          <Col className="tickets">
            <Col className="tickets-header">
              <Row className="align-items-center">
                <Col lg={9}>
                  <h3>Tickets de soporte</h3>
                </Col>
                <Col lg={3} className="right icon">
                  <FaPlusCircle onClick={() => navigate("/new-ticket")} />
                </Col>
              </Row>
            </Col>
            <Row>
              <Col lg={4} sm={12} className="tickets-content">
                <h4 className="mb-2">Pendientes</h4>
                {pendingTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.created_at}
                    userName={item.userName}
                    accountName={item.accountName}
                    status={item.status}
                  />
                ))}
              </Col>
              <Col lg={4} sm={12} className="tickets-content">
                <h4 className="mb-2">Abiertos</h4>
                {openTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.assigned_at}
                    userName={item.userName}
                    accountName={item.accountName}
                    supportName={item.supportUserName}
                    status={item.status}
                    supportClosed={item.supportClosed}
                  />
                ))}
              </Col>
              <Col lg={4} sm={12} className="tickets-content">
                <h4 className="mb-2">Cerrados</h4>
                {closedTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.closed_at}
                    userName={item.userName}
                    supportName={item.supportUserName}
                    accountName={item.accountName}
                    status={item.status}
                    closed_at={item.closed_at}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        );
      } else if (state.role === "admin") {
        const openTickets = tickets.filter(
          (item) => item.status === "open" || item.status === "pending"
        );
        const closedTickets = tickets.filter(
          (item) => item.status === "closed"
        );
        return (
          <Col className="tickets">
            <Col className="tickets-header">
              <Row className="align-items-center">
                <Col lg={9}>
                  <h3>Tickets de soporte</h3>
                </Col>
                <Col lg={3} className="right icon">
                  <FaPlusCircle onClick={() => navigate("/new-ticket")} />
                </Col>
              </Row>
            </Col>
            <Row>
              <Col lg={6} sm={12} className="tickets-content">
                <h4 className="mb-2">Abiertos</h4>
                {openTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.assigned_at}
                    userName={item.userName}
                    accountName={item.accountName}
                    supportName={item.supportUserName}
                    status={item.status}
                    supportClosed={item.supportClosed}
                  />
                ))}
              </Col>
              <Col lg={6} sm={12} className="tickets-content">
                <h4 className="mb-2">Cerrados</h4>
                {closedTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.closed_at}
                    userName={item.userName}
                    supportName={item.supportUserName}
                    accountName={item.accountName}
                    status={item.status}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        );
      } else if (state.role === "support") {
        const openTickets = tickets.filter((item) => item.status === "open");
        const closedTickets = tickets.filter(
          (item) => item.status === "closed"
        );
        return (
          <Col className="tickets">
            <Col className="tickets-header">
              <Row className="align-items-center">
                <Col lg={9}>
                  <h3>Tickets de soporte</h3>
                </Col>
                <Col lg={3} className="right icon">
                  <FaPlusCircle onClick={() => navigate("/new-ticket")} />
                </Col>
              </Row>
            </Col>
            <Row>
              <Col lg={6} sm={12} className="tickets-content">
                <h4 className="mb-2">Abiertos</h4>
                {openTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.assigned_at}
                    userName={item.userName}
                    accountName={item.accountName}
                    supportName={item.supportUserName}
                    status={item.status}
                    supportClosed={item.supportClosed}
                  />
                ))}
              </Col>
              <Col lg={6} sm={12} className="tickets-content">
                <h4 className="mb-2">Cerrados</h4>
                {closedTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.closed_at}
                    userName={item.userName}
                    supportName={item.supportUserName}
                    accountName={item.accountName}
                    status={item.status}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        );
      } /* else {
        const openTickets = tickets.filter((item) => item.status === "open");
        const closedTickets = tickets.filter(
          (item) => item.status === "closed"
        );
        return (
          <Col className="tickets">
            <Col className="tickets-header">
              <Row className="align-items-center">
                <Col lg={9}>
                  <h3>Tickets de soporte</h3>
                </Col>
                <Col lg={3} className="right icon">
                  <FaPlusCircle onClick={() => navigate("/new-ticket")} />
                </Col>
              </Row>
            </Col>
            <Row>
              <Col lg={6} sm={12} className="tickets-content">
                <h4 className="mb-2">Abiertos</h4>
                {openTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.assigned_at}
                    userName={item.userName}
                    accountName={item.accountName}
                    supportName={item.supportUserName}
                    status={item.status}
                    supportClosed={item.supportClosed}
                  />
                ))}
              </Col>
              <Col lg={6} sm={12} className="tickets-content">
                <h4 className="mb-2">Cerrados</h4>
                {closedTickets.map((item) => (
                  <Ticket
                    description={item.description}
                    title={item.title}
                    id={item._id}
                    key={item._id}
                    date={item.closed_at}
                    userName={item.userName}
                    supportName={item.supportUserName}
                    accountName={item.accountName}
                    status={item.status}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        );
      } */
    } else {
      return (
        <Col className="tickets">
          <Col className="tickets-empty">
            <h4 className="mb-3">No hay tickets reportados</h4>
            <Button onClick={() => navigate("/new-ticket")} variant="info">
              Crear primer ticket
            </Button>
          </Col>
        </Col>
      );
    }
  }
}

export default Tickets;
