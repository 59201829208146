import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

function MyAccount() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [confirmationText, setConfirmationText] = useState("");

  const { state, signout } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/${state.userId}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setUser(response.data.user);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleSignOut = () => {
    navigate("/");
    signout();
  };

  const handleConfirmationTextChange = (e) => {
    setConfirmationText(e.target.value);
  };

  const handleDelete = async () => {
    if (confirmationText !== "eliminar") {
      toast("La palabra no coincide, inténtalo de nuevo");
    } else {
      try {
        await axios({
          method: "delete",
          url: `${process.env.REACT_APP_BACKEND_URL}/users/delete/account/${user._id}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        toast("Solicitud de eliminación de cuenta enviada");
        setTimeout(() => {
          handleSignOut();
        }, 2000);
      } catch (error) {
        toast(`${error.response.data.message}`);
      }
    }
  };

  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <Container>
        <ToastContainer />
        <Row>
          <Col>
            <h4>{`${user.firstName} ${user.lastName}`}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Form.Group className="mb-3">
                <Form.Text className="text-muted">
                  Para eliminar tu cuenta escribe la palabra "eliminar" en el
                  siguiente campo y da click en el botón.
                </Form.Text>
                <Form.Control
                  type="text"
                  onChange={(e) => handleConfirmationTextChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Button variant="danger" onClick={() => handleDelete()}>
                  Eliminar mi cuenta
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MyAccount;
