import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
const dayjs = require("dayjs");

function Report({
  id,
  title,
  type,
  survey_id,
  start_date,
  end_date,
  removeReport,
}) {
  let content = <></>;

  const navigate = useNavigate();

  if (type === "survey") {
    content = (
      <Col className="report-element">
        <Row className="align-items-center">
          <Col lg={5}>
            <h5 onClick={() => navigate(`/report-survey-details/${survey_id}`)}>
              {title}
            </h5>
          </Col>
          <Col lg={3}>{dayjs(start_date).format("MMM DD")}</Col>
          <Col lg={4} className="right">
            <Button size="sm" onClick={() => removeReport(id)} variant="danger">
              Eliminar
            </Button>
          </Col>
        </Row>
      </Col>
    );
  } else if (type === "timeline") {
    content = (
      <Col className="report-element">
        <Row className="align-items-center">
          <Col lg={5}>
            <h5 onClick={() => navigate(`/report-timeline-details/${id}`)}>
              {title}
            </h5>
          </Col>
          <Col lg={3}>
            {dayjs(start_date).format("MMM DD")}
            {" - "}
            {dayjs(end_date).format("MMM DD")}
          </Col>
          <Col lg={4} className="right">
            <Button size="sm" onClick={() => removeReport(id)} variant="danger">
              Eliminar
            </Button>
          </Col>
        </Row>
      </Col>
    );
  } else if (type === "finance") {
    content = (
      <Col className="report-element">
        <Row className="align-items-center">
          <Col lg={5}>
            <h5 onClick={() => navigate(`/report-finance-details/${id}`)}>
              {title}
            </h5>
          </Col>
          <Col lg={3}>
            {dayjs(start_date).format("MMM DD")}
            {" - "}
            {dayjs(end_date).format("MMM DD")}
          </Col>
          <Col lg={4} className="right">
            <Button size="sm" onClick={() => removeReport(id)} variant="danger">
              Eliminar
            </Button>
          </Col>
        </Row>
      </Col>
    );
  } else if (type === "morosity") {
    content = (
      <Col className="report-element">
        <Row className="align-items-center">
          <Col lg={10}>
            <h5 onClick={() => navigate(`/report-morosity-details/${id}`)}>
              {title}
            </h5>
          </Col>
          <Col lg={2} className="right">
            <Button size="sm" onClick={() => removeReport(id)} variant="danger">
              Eliminar
            </Button>
          </Col>
        </Row>
      </Col>
    );
  } else if (type === "payments") {
    content = (
      <Col className="report-element">
        <Row className="align-items-center">
          <Col lg={5}>
            <h5 onClick={() => navigate(`/report-payments-details/${id}`)}>
              {title}
            </h5>
          </Col>
          <Col lg={3}>
            {dayjs(start_date).format("MMM DD")}
            {" - "}
            {dayjs(end_date).format("MMM DD")}
          </Col>
          <Col lg={4} className="right">
            <Button size="sm" onClick={() => removeReport(id)} variant="danger">
              Eliminar
            </Button>
          </Col>
        </Row>
      </Col>
    );
  }
  return content;
}

export default Report;
