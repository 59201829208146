import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Account from "../Components/Account";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function Accounts() {
  const { state, changeAccount } = useContext(AuthContext);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts/`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setAccounts(response.data.accounts);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="accounts">
        <Col className="accounts-header">
          <Row className="align-items-center">
            <Col lg={9}>
              <ToastContainer />
              <h4>Fraccionamientos</h4>
            </Col>
            <Col lg={3} className="right icon">
              <FaPlusCircle onClick={() => navigate("/new-account")} />
            </Col>
          </Row>
        </Col>
        <Col className="accounts-content">
          {accounts.map((item) => (
            <Account
              name={item.name}
              id={item._id}
              key={item._id}
              changeAccount={changeAccount}
            />
          ))}
        </Col>
      </Col>
    );
  }
}

export default Accounts;
