import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import PieChartElement from "../Components/PieChartElement";

function SurveyReport() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/survey/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setData(result.data.answersData);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="survey-report center">
        {data.map((item) => (
          <PieChartElement
            key={item.id}
            title={item.question}
            answers={item.answers}
          />
        ))}
      </Col>
    );
  }
}

export default SurveyReport;
