import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Residence({ address, id }) {
  const navigate = useNavigate();

  return (
    <Col className="residence-element">
      <Row>
        <Col lg={6}>
          <h5 onClick={() => navigate(`/residence-details/${id}`)}>
            {address}
          </h5>
        </Col>
        <Col lg={6} className="right">
          <Button
            size="sm"
            onClick={() => navigate(`/edit-residence/${id}`)}
            variant="primary"
            style={{ marginRight: 10 }}
          >
            Editar
          </Button>
          <Button
            size="sm"
            onClick={() => navigate(`/edit-residence/balance/${id}`)}
            variant="info"
            style={{ marginRight: 10 }}
          >
            Balance inicial
          </Button>
          <Button
            size="sm"
            onClick={() => navigate(`/edit-residence/discount/${id}`)}
            variant="dark"
            style={{ marginRight: 10 }}
          >
            Descuento
          </Button>
          <Button
            size="sm"
            onClick={() => navigate(`/delete-residence/${id}`)}
            variant="danger"
          >
            Eliminar
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default Residence;
