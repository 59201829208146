import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import ManualPayment from "../Components/ManualPayment";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";

function ManualPayments() {
  const { state } = useContext(AuthContext);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  let { rid } = useParams();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/manualPayments/residence/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setPayments(response.data.manualPayments);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const deletePayment = async (id) => {
    setIsLoading(true);
    await axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}/manualPayments/${id}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/manualPayments/residence/${rid}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setPayments(response.data.manualPayments);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    if (payments.length > 0) {
      return (
        <>
          <Col className="manual-payments">
            <Col className="manual-payments-header">
              <Row className="align-items-center">
                <Col lg={9}>
                  <ToastContainer />
                  <h4>Pagos en efectivo</h4>
                </Col>
                <Col lg={3} className="right icon">
                  <FaPlusCircle
                    onClick={() => navigate(`/new-manual-payment/${rid}`)}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="manual-payments-content">
              {payments.map((item) => (
                <ManualPayment
                  amount={item.amount}
                  date={item.created_at}
                  id={item._id}
                  key={item._id}
                  deletePayment={deletePayment}
                  rid={rid}
                />
              ))}
            </Col>
          </Col>
          <Button
            variant="secondary"
            className="mt-3"
            onClick={() => navigate(`/residence-details/${rid}`)}
          >
            Regresar
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Col className="manual-payments">
            <Col className="manual-payments-header">
              <Row className="align-items-center">
                <Col lg={9}>
                  <ToastContainer />
                  <h4>Pagos en efectivo</h4>
                </Col>
                <Col lg={3} className="right icon">
                  <FaPlusCircle
                    onClick={() => navigate(`/new-manual-payment/${rid}`)}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="manual-payments-content center">
              <h4>No hay pagos registrados para esta residencia</h4>
            </Col>
          </Col>
          <Button
            variant="secondary"
            className="mt-3"
            onClick={() => navigate(`/residence-details/${rid}`)}
          >
            Regresar
          </Button>
        </>
      );
    }
  }
}

export default ManualPayments;
