import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

function EditPayout() {
  const [account, setAccount] = useState();
  const [payout, setPayout] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let { aid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts/${aid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setAccount(response.data.account);
      setPayout(response.data.account.payment);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handlePayoutChange = (evt) => {
    setPayout(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts/payout/${aid}`,
        data: {
          payout: payout,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/accounts");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="new-account">
        <Col>
          <h4>Editar cuota anual - {account.name}</h4>
        </Col>
        <Col className="new-account-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Nombre del fraccionamiento"
                onChange={handlePayoutChange}
                value={payout}
              />
            </Form.Group>
            <Form.Group>
              <Button variant="info" onClick={handleSubmit}>
                Guardar
              </Button>
              <Button variant="danger" onClick={() => navigate("/accounts")}>
                Cancelar
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Col>
    );
  }
}

export default EditPayout;
