import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function Residence({ id, address, total }) {
  return (
    <Col className="residence-element" id={id}>
      <Row>
        <Col lg={9}>{address}</Col>
        <Col lg={3}>${total}</Col>
      </Row>
    </Col>
  );
}

export default Residence;
