import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";

function NewAccount() {
  const [name, setName] = useState();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const handleNameChange = (evt) => {
    setName(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts/`,
        data: {
          name: name,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/accounts");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Col className="new-account">
      <Col>
        <h4>Nuevo fraccionamiento</h4>
      </Col>
      <Col className="new-account-form">
        <Form>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Nombre del fraccionamiento"
              onChange={handleNameChange}
            />
          </Form.Group>
          <Form.Group>
            <Button variant="info" onClick={handleSubmit}>
              Guardar
            </Button>
            <Button variant="danger" onClick={() => navigate("/accounts")}>
              Cancelar
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Col>
  );
}

export default NewAccount;
