import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";

function NewUser() {
  const roles = [
    {
      id: uuidv4(),
      label: "Administrador",
      value: "admin",
    },
    {
      id: uuidv4(),
      label: "Cabeza de hogar",
      value: "head",
    },
    {
      id: uuidv4(),
      label: "Usuario",
      value: "user",
    },
  ];

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [date, setDate] = useState(new Date());
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("head");

  let { rid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const handleFirstNameChange = (evt) => {
    setFirstName(evt.target.value);
  };

  const handleLastNameChange = (evt) => {
    setLastName(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handlePhoneChange = (evt) => {
    setPhone(evt.target.value);
  };

  const handleRoleChange = (evt) => {
    setRole(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/`,
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          role: role,
          account: state.account,
          user_id: state.userId,
          residence: rid,
          date: date,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate(`/residence-details/${rid}`);
    } catch (error) {
      toast(error.response.data.message)
      console.log(error);
    }
  };
  return (
    <Col className="new-user">
      <Col>
        <h4>Nuevo usuario</h4>
        <ToastContainer />
      </Col>
      <Col className="new-user-form">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Nombres</Form.Label>
            <Form.Control type="text" onChange={handleFirstNameChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Apellidos</Form.Label>
            <Form.Control type="text" onChange={handleLastNameChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" onChange={handleEmailChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Fecha de nacimiento</Form.Label>
            <DatePicker
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              selected={date}
              onChange={(date) => setDate(date)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control type="text" onChange={handlePhoneChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Rol</Form.Label>
            <Form.Select onChange={handleRoleChange} defaultValue="user">
              {roles.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Button variant="info" onClick={handleSubmit}>
              Guardar
            </Button>
            <Button
              variant="danger"
              onClick={() => navigate(`/residence-details/${rid}`)}
            >
              Cancelar
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Col>
  );
}

export default NewUser;
