import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
const dayjs = require("dayjs");

function Expense({ id, title, amount, date}) {
  const navigate = useNavigate();

  return (
    <Col className="expense-element">
      <Row className="align-items-center">
        <Col lg={5}>
          <h4>{title}</h4>
        </Col>
        <Col lg={2}>
          <h5>${amount}</h5>
        </Col>
        <Col lg={2}>
          <h6>{dayjs(date).format("DD-MMM-YYYY")}</h6>
        </Col>
        <Col lg={3} className="right">
          <Button
            size="sm"
            onClick={() => navigate(`/edit-expense/${id}`)}
            variant="info"
            style={{ marginRight: 10 }}
          >
            Editar
          </Button>
          <Button size="sm" onClick={() => navigate(`/delete-expense/${id}`)} variant="danger">
            Eliminar
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default Expense;
