import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

function DeleteResidence() {
  const [residence, setResidence] = useState();
  const [isLoading, setIsLoading] = useState(true);

  let { rid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/residences/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setResidence(response.data.residence);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleSubmit = async () => {
    try {
      await axios({
        method: "delete",
        url: `${process.env.REACT_APP_BACKEND_URL}/residences/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/residences");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="delete-residence">
        <Col>
          <h4>Eliminar residencia</h4>
        </Col>
        <Col className="delete-residence-form">
          <Col className="mb-3">
            <p>{`Estas por eliminar la residencia ${residence.address}. Una vez eliminada no podrás recuperar su información. ¿Estás seguro?`}</p>
          </Col>
          <Col>
            <Button variant="info" onClick={handleSubmit}>
              Eliminar
            </Button>
            <Button variant="danger" onClick={() => navigate("/residences")}>
              Cancelar
            </Button>
          </Col>
        </Col>
      </Col>
    );
  }
}

export default DeleteResidence;
