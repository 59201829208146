import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainContainer from "./MainContainer";
import { AuthProvider } from "./Context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <MainContainer />
    </AuthProvider>
  );
}

export default App;
