import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Logo from "../../Shared/Images/SafehutLogoBlack.png";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { signin } = useContext(AuthContext);

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users/login`,
        {
          email: email,
          password: password,
        }
      );

      const user = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users/me/${email}`
      );

      signin(
        user.data.user_id,
        response.data.token,
        user.data.role,
        user.data.account
      );
    } catch (error) {
      console.log(error);
      toast(`${error.response.data.message}`);
    }
  };

  return (
    <Col lg={12} className="login-container">
      <Row className="justify-content-center">
        <Col lg={4}>
          <Col className="center">
            <Image fluid src={Logo} />
            <ToastContainer />
          </Col>
          <Col className="login mb-3">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Usuario</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingresar email"
                  onChange={handleEmailChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Ingresar contraseña"
                  onChange={handlePasswordChange}
                />
              </Form.Group>
              <Form.Group className="d-grid gap-2">
                <Button variant="info" onClick={handleSubmit}>
                  Ingresar
                </Button>
              </Form.Group>
            </Form>
          </Col>
          <Col className="center disclaimer">
            {/* <NavLink to="/support">
              <p>¿Tienes un problema y necesitas soporte? Da click aquí</p>
            </NavLink> */}
            <p>Si deseas eliminar tu cuenta, por favor ingresa con tu usuario y contraseña y selecciona la sección "Mi cuenta" en el menú principal.</p>
          </Col>
        </Col>
      </Row>
    </Col>
  );
}

export default Login;
