import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

function EditExpense() {
  const [amount, setAmount] = useState(0);
  const [title, setTitle] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  let { eid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/expenses/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTitle(response.data.expense.title);
      setAmount(response.data.expense.amount);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleAmountChange = (evt) => {
    setAmount(evt.target.value);
  };

  const handleTitleChange = (evt) => {
    setTitle(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/expenses/${eid}`,
        data: {
          title: title,
          amount: amount,
          approver: state.userId,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/expenses");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="new-expense">
        <Col>
          <h4>Editar egreso</h4>
        </Col>
        <Col className="new-expense-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Titulo"
                onChange={handleTitleChange}
                value={title}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="Cantidad"
                onChange={handleAmountChange}
                value={amount}
              />
            </Form.Group>
            <Form.Group>
              <Button variant="info" onClick={handleSubmit}>
                Guardar
              </Button>
              <Button variant="danger" onClick={() => navigate("/expenses")}>
                Cancelar
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Col>
    );
  }
}

export default EditExpense;
