import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

function NewNotice() {
  const types = [
    {
      id: uuidv4(),
      label: "normal",
      value: "normal",
    },
    {
      id: uuidv4(),
      label: "importante",
      value: "importante",
    },
  ];

  const [message, setMessage] = useState("");
  const [type, setType] = useState("normal");

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const handleMessageChange = (evt) => {
    setMessage(evt.target.value);
  };

  const handleTypeChange = (evt) => {
    setType(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/notices/`,
        data: {
          message: message,
          notice_type: type,
          account: state.account,
          user: state.userId,
          event_type: "New notice",
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/notices");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Col className="new-notice">
      <Col>
        <h4>Nuevo aviso</h4>
      </Col>
      <Col className="new-notice-form">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Mensaje</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Escribe el mensaje"
              onChange={handleMessageChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nivel de importancia</Form.Label>
            <Form.Select onChange={handleTypeChange}>
              {types.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Button variant="info" onClick={handleSubmit}>
              Guardar
            </Button>
            <Button variant="danger" onClick={() => navigate("/notices")}>
              Cancelar
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Col>
  );
}

export default NewNotice;
