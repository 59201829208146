import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import AuthContext from "../src/Context/AuthContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Login from "./SignIn/Pages/Login";
import Menu from "./Navigation/Components/Menu";
import NavBar from "./Navigation/Components/NavBar";
import MainContent from "./Navigation/Pages/MainContent";
import { Route, Routes } from "react-router-dom";

import Support from "./Support/Pages/Support";
import Success from "./Support/Pages/Success";

function MainContainer() {
  const { state, signin } = useContext(AuthContext);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("UserData"));
    if (storedData && storedData.token) {
      signin(
        storedData.userId,
        storedData.token,
        storedData.role,
        storedData.account
      );
    }
  }, []);

  if (state.isLoggedIn) {
    return (
      <Container fluid>
        <Row>
          <Col lg={2} md={2} className="menu">
            <Menu />
          </Col>
          <Col lg={10} md={10} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Col className="main-content pt-5">
              <MainContent />
            </Col>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col lg={12} className="main-background">
            <Row className="justify-content-center">
              <Routes>
                <Route path="/" element={<Login />}></Route>
                <Route path="/support" element={<Support />}></Route>
                <Route path="/success" element={<Success />}></Route>
              </Routes>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MainContainer;
