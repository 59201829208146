import React, { useEffect, useContext, useState } from "react";
import Residence from "../Components/Residence";
import Spinner from "react-bootstrap/Spinner";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { useParams } from "react-router-dom";

function PaymentsReport() {
  const [residences, setResidences] = useState();
  const [total, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/residences/payments/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setResidences(result.data.residences);
      setTotal(result.data.total);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading) {
    return (
      <Col>
        <Spinner animation="border" className="mb-2" />
        <h4>Procesando... Puede tardar algunos minutos, favor de esperar.</h4>
      </Col>
    );
  } else {
    return (
      <Col className="residences">
        <Col className="residences-header">
          <Row className="align-items-center">
            <Col className="mb-2">
              <h4>Pagos registrados</h4>
            </Col>
          </Row>
        </Col>
        <Col className="residences-content mb-3">
          <Col>
            <Row className="align-items-center">
              <Col lg={9}>
                <h6>Dirección</h6>
              </Col>
              <Col lg={3}>
                <p>Suma de pagos</p>
              </Col>
            </Row>
          </Col>
          {residences.map((item) => (
            <Residence
              address={item.address}
              total={item.total}
              id={item._id}
              key={item._id}
            />
          ))}
        </Col>
        <Col className="residences-total">
          <Row>
            <Col lg={9}>
              <h5>Total</h5>
            </Col>
            <Col lg={3}>
              <p>${total}</p>
            </Col>
          </Row>
        </Col>
      </Col>
    );
  }
}

export default PaymentsReport;
