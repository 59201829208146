import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AuthContext from "../../Context/AuthContext";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Dashboard() {
  const [users, setUsers] = useState(0);
  const [residences, setResidences] = useState(0);
  const [account, setAccount] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { state, signout } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      try {
        const result = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/accounts/dashboard/${state.account}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccount(result.data.account);
        setUsers(result.data.users);
        setResidences(result.data.residences);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          signout();
        }
      }
    }
    fetch();
  }, []);

  const handleOpenDoor = async (name) => {
    try {
      const result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/access/`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
        data: {
          door: name,
          user_id: state.userId,
          event_type: `Open${name}Door`,
          account_id: state.account,
        },
      });
      toast(`${result.data.message}`);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    if (state.role === "admin" || state.role === "root") {
      return (
        <Col className="dashboard">
          <ToastContainer />
          <Col className="center mb-4">
            <h1>{account.name}</h1>
          </Col>
          <Row className="justify-content-center align-items-center mb-4">
            <Col lg={5} className="dashboard-residents center">
              <h4>Residentes registrados</h4>
              <h2 className="counter">{users}</h2>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5} className="dashboard-residents center">
              <h4>Puertas disponibles</h4>
              {account.doors.map((item) => (
                <Col>
                  <Button
                    size="lg"
                    key={item._id}
                    variant="info"
                    onClick={() => handleOpenDoor(item.name)}
                  >
                    {item.name}
                  </Button>
                </Col>
              ))}
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center mb-4">
            <Col lg={5} className="dashboard-residents center">
              <h4>Residencias registradas</h4>
              <h2 className="counter">{residences}</h2>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}></Col>
          </Row>
        </Col>
      );
    } else {
      return (
        <Col className="center sublime-text">
          <h2>Esta sección es exclusiva para administradores de comité</h2>
        </Col>
      );
    }
  }
}

export default Dashboard;
