import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Report from "../Components/Report";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Reports() {
  const { state } = useContext(AuthContext);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/account/${state.account}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setReports(response.data.reports);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const removeReport = async (id) => {
    setIsLoading(true);
    await axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/${id}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/account/${state.account}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setReports(result.data.reports);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    if (reports.length < 1) {
      return (
        <Col className="reports">
          <Col className="reports-header">
            <Row className="align-items-center">
              <Col lg={9}>
                <h4>Reportes</h4>
              </Col>
              <Col lg={3} className="right icon">
                <FaPlusCircle onClick={() => navigate("/new-report")} />
              </Col>
            </Row>
          </Col>
          <Col className="reports-content center">
            <h4>No se han creado reportes para mostrar</h4>
          </Col>
        </Col>
      );
    } else {
      return (
        <Col className="reports">
          <Col className="reports-header">
            <Row className="align-items-center">
              <Col lg={9}>
                <h4>Reportes</h4>
              </Col>
              <Col lg={3} className="right icon">
                <FaPlusCircle onClick={() => navigate("/new-report")} />
              </Col>
            </Row>
          </Col>
          <Col className="reports-content">
            {reports.map((item) => (
              <Report
                key={item._id}
                id={item._id}
                title={item.title}
                type={item.report_type}
                start_date={item.start_date}
                end_date={item.end_date}
                survey_id={item.survey_id}
                removeReport={removeReport}
              />
            ))}
          </Col>
        </Col>
      );
    }
  }
}

export default Reports;
