import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Notice from "../Components/Notice";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

function Notices() {
  const [notices, setNotices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/notices/account/${state.account}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setNotices(response.data.notices);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const deleteNotice = async (id) => {
    await axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}/notices/${id}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/notices/account/${state.account}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setNotices(result.data.notices);
  };

  if (isLoading) {
    return <Spinner />;
  } else {
    if (notices.length > 0) {
      return (
        <Col className="notices">
          <Col className="notices-header">
            <Row className="align-items-center">
              <Col lg={9}>
                <h4>Avisos</h4>
              </Col>
              <Col lg={3} className="right icon">
                <FaPlusCircle onClick={() => navigate("/new-notice")} />
              </Col>
            </Row>
          </Col>
          <Col className="notices-content">
            {notices.map((item) => (
              <Notice
                message={item.message}
                id={item._id}
                key={item._id}
                date={item.created_at}
                deleteNotice={deleteNotice}
              />
            ))}
          </Col>
        </Col>
      );
    } else {
      return (
        <Col className="notices">
          <Col className="notices-empty">
            <h4 className="mb-3">No hay avisos en este fraccionamiento</h4>
            <Button onClick={() => navigate("/new-notice")} variant="info">
              Crear primer aviso
            </Button>
          </Col>
        </Col>
      );
    }
  }
}

export default Notices;
