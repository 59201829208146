import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import Spinner from "react-bootstrap/Spinner";

function NewReport() {
  const types = [
    {
      id: uuidv4(),
      label: "Encuesta",
      value: "survey",
    },
    {
      id: uuidv4(),
      label: "Financiero",
      value: "finance",
    },
    {
      id: uuidv4(),
      label: "Linea de tiempo",
      value: "timeline",
    },
    {
      id: uuidv4(),
      label: "Morosidad",
      value: "morosity",
    },
    {
      id: uuidv4(),
      label: "Pagos",
      value: "payments",
    },
  ];

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [surveys, setSurveys] = useState([]);
  const [survey, setSurvey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/surveys/${state.account}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      let newArray = result.data.surveys.map((element) => ({
        id: element._id,
        label: element.title,
        value: element._id,
      }));
      setSurveys(newArray);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleTitleChange = (evt) => {
    setTitle(evt.target.value);
  };

  const handleTypeChange = (evt) => {
    setType(evt.target.value);
    if (evt.target.value === "morosity") {
      setStartDate(new Date());
    }
  };

  const handleSurveyChange = (evt) => {
    setSurvey(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/`,
        data: {
          title: title,
          report_type: type,
          account_id: state.account,
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          survey_id: survey,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/reports");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="new-report">
        <Col>
          <h4>Nuevo reporte</h4>
        </Col>
        <Col className="new-report-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control type="text" onChange={handleTitleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tipo</Form.Label>
              <Form.Select onChange={handleTypeChange} value={type}>
                <option>Seleccionar tipo</option>
                {types.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {type === "finance" ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha de inicio</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha de término</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </Form.Group>
              </>
            ) : type === "timeline" ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha de inicio</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha de término</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </Form.Group>
              </>
            ) : type === "survey" ? (
              <Form.Group className="mb-3">
                <Form.Label>Encuesta</Form.Label>
                <Form.Select onChange={handleSurveyChange}>
                  <option>Seleccionar encuesta</option>
                  {surveys.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : type === "morosity" ? (
              <></>
            ) : type === "payments" ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha de inicio</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha de término</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </Form.Group>
              </>
            ) : (
              <></>
            )}

            <Form.Group>
              <Button variant="info" onClick={handleSubmit}>
                Guardar
              </Button>
              <Button variant="danger" onClick={() => navigate(`/reports`)}>
                Cancelar
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Col>
    );
  }
}

export default NewReport;
