import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

function EditCardCode() {
  const [user, setUser] = useState();
  const [cardCode, setCardCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let { uid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/${uid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setUser(response.data.user);
      setCardCode(response.data.user.card_code);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleCardCodeChange = (evt) => {
    setCardCode(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/update/cardcode`,
        data: {
          card_code: cardCode,
          userId: uid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate(`/residence-details/${user.residence}`);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="new-user">
        <Col>
          <h4>Editar número de tarjeta</h4>
        </Col>
        <Col className="new-user-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Número de tarjeta</Form.Label>
              <Form.Control
                type="number"
                onChange={handleCardCodeChange}
                value={cardCode}
              />
            </Form.Group>
            <Form.Group>
              <Button variant="info" onClick={handleSubmit}>
                Guardar
              </Button>
              <Button
                variant="danger"
                onClick={() => navigate(`/residence-details/${user.residence}`)}
              >
                Cancelar
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Col>
    );
  }
}

export default EditCardCode;
