import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";

function NewManualPayment() {
  const [amount, setAmount] = useState();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  const handleAmountChange = (evt) => {
    setAmount(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/manualPayments/`,
        data: {
          amount: amount,
          residence: rid,
          approver: state.userId,
          account: state.account,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate(`/manual-payments/${rid}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Col className="new-manual-payment">
      <Col>
        <h4>Nuevo pago en efectivo</h4>
      </Col>
      <Col className="new-manual-payment-form">
        <Form>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Cantidad"
              onChange={handleAmountChange}
            />
          </Form.Group>
          <Form.Group>
            <Button variant="info" onClick={handleSubmit}>
              Guardar
            </Button>
            <Button
              variant="danger"
              onClick={() => navigate(`/manual-payments/${rid}`)}
            >
              Cancelar
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Col>
  );
}

export default NewManualPayment;
