import React, { useContext } from "react";
import Nav from "react-bootstrap/Nav";
import AuthContext from "../../Context/AuthContext";
import Image from "react-bootstrap/Image";
import Logo from "../../Shared/Images/SafehutLogo.png";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaTachometerAlt,
  FaKaaba,
  FaCommentAlt,
  FaLaptopHouse,
  FaChartLine,
  FaUser,
  FaSignOutAlt,
  FaTicketAlt,
  FaMoneyCheck,
} from "react-icons/fa";

function Menu() {
  const { state, signout } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSignOut = () => {
    navigate("/");
    signout();
  };

  if (state.role === "root") {
    return (
      <Nav
        expand="lg"
        defaultActiveKey="/home"
        className="flex-column safehut-menu"
      >
        <Nav.Item className="center">
          <Image className="logo-menu" src={Logo} fluid />
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/dashboard">
            <FaTachometerAlt />
            Dashboard
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/accounts">
            <FaKaaba />
            Fraccionamientos
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/notices">
            <FaCommentAlt />
            Avisos
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/residences">
            <FaLaptopHouse />
            Residencias
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/reports">
            <FaChartLine />
            Reportes
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/expenses">
            <FaMoneyCheck />
            Egresos
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/tickets">
            <FaTicketAlt />
            Tickets
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/" onClick={() => handleSignOut()}>
            <FaSignOutAlt />
            Salir
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  } else if (state.role === "admin") {
    return (
      <Nav defaultActiveKey="/home" className="flex-column safehut-menu">
        <Nav.Item className="center">
          <Image className="logo-menu" src={Logo} fluid />
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/dashboard">
            <FaTachometerAlt />
            Dashboard
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/notices">
            <FaCommentAlt />
            Avisos
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/residences">
            <FaLaptopHouse />
            Residencias
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/reports">
            <FaChartLine />
            Reportes
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/expenses">
            <FaMoneyCheck />
            Egresos
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/tickets">
            <FaTicketAlt />
            Tickets
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/my-account">
            <FaUser />
            Mi cuenta
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/" onClick={() => handleSignOut()}>
            <FaSignOutAlt />
            Salir
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  } else if (state.role === "support") {
    return (
      <Nav className="flex-column safehut-menu">
        <Nav.Item className="center">
          <Image className="logo-menu" src={Logo} fluid />
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/dashboard">
            <FaTachometerAlt />
            Dashboard
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/tickets">
            <FaTicketAlt />
            Tickets
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/" onClick={() => handleSignOut()}>
            <FaSignOutAlt />
            Salir
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  } else {
    return (
      <Nav defaultActiveKey="/home" className="flex-column safehut-menu">
        <Nav.Item className="center">
          <Image className="logo-menu" src={Logo} fluid />
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/dashboard">
            <FaTachometerAlt />
            Dashboard
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/my-account">
            <FaUser />
            Mi cuenta
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/" onClick={() => handleSignOut()}>
            <FaSignOutAlt />
            Salir
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  }
}

export default Menu;
