import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";

function Support() {
  const areas = [
    {
      id: uuidv4(),
      label: "Mantenimiento",
      value: "mantenimiento",
    },
    {
      id: uuidv4(),
      label: "Software",
      value: "software",
    },
    {
      id: uuidv4(),
      label: "Ventas",
      value: "ventas",
    },
  ];

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [complex, setComplex] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");

  const navigate = useNavigate();

  const handleNameChange = (evt) => {
    setName(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleComplexChange = (evt) => {
    setComplex(evt.target.value);
  };

  const handleMessageChange = (evt) => {
    setMessage(evt.target.value);
  };

  const handleAreaChange = (evt) => {
    setArea(evt.target.value);
  };

  const handlePhoneChange = (evt) => {
    setPhone(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/support/ticket`,
        data: {
          name: name,
          email: email,
          complex: complex,
          message: message,
          phone: phone,
          area: area,
        },
      });
      navigate("/success");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Col lg={8} className="support">
      <Col className="center">
        <h4>REQUERIMIENTO DE SOPORTE</h4>
      </Col>
      <Col className="support-form">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Nombre completo</Form.Label>
            <Form.Control type="text" onChange={handleNameChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email de contacto</Form.Label>
            <Form.Control type="email" onChange={handleEmailChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Fraccionamiento o cerrada al que perteneces</Form.Label>
            <Form.Control type="text" onChange={handleComplexChange} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Área competente a tu problema</Form.Label>
            <Form.Select onChange={handleAreaChange} value={area}>
              <option>Seleccionar area</option>
              {areas.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Platicanos acerca de tu problema</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              onChange={handleMessageChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Teléfono de contacto</Form.Label>
            <Form.Control type="text" onChange={handlePhoneChange} />
          </Form.Group>
          <Form.Group>
            <Button variant="info" onClick={handleSubmit}>
              ENVIAR
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Col>
  );
}

export default Support;
