import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import Spinner from "react-bootstrap/Spinner";

function EditUser() {
  const roles = [
    {
      id: uuidv4(),
      label: "Administrador",
      value: "admin",
    },
    {
      id: uuidv4(),
      label: "Cabeza de hogar",
      value: "head",
    },
    {
      id: uuidv4(),
      label: "Usuario",
      value: "user",
    },
  ];

  const [user, setUser] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [date, setDate] = useState(new Date());
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let { uid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/${uid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setUser(response.data.user);
      setFirstName(response.data.user.firstName);
      setLastName(response.data.user.lastName);
      setEmail(response.data.user.email);
      setPhone(response.data.user.phone);
      setRole(response.data.user.role);

      if (response.data.user.birthday != null) {
        setDate(new Date(response.data.user.birthday));
      }

      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleFirstNameChange = (evt) => {
    setFirstName(evt.target.value);
  };

  const handleLastNameChange = (evt) => {
    setLastName(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handlePhoneChange = (evt) => {
    setPhone(evt.target.value);
  };

  const handleRoleChange = (evt) => {
    setRole(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/${uid}`,
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          role: role,
          birthday: date,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate(`/residence-details/${user.residence}`);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="new-user">
        <Col>
          <h4>Editar usuario</h4>
        </Col>
        <Col className="new-user-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nombres</Form.Label>
              <Form.Control
                type="text"
                onChange={handleFirstNameChange}
                value={firstName}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                type="text"
                onChange={handleLastNameChange}
                value={lastName}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                onChange={handleEmailChange}
                value={email}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Fecha de nacimiento</Form.Label>
              <DatePicker
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                selected={date}
                onChange={(date) => setDate(date)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                onChange={handlePhoneChange}
                value={phone}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Rol</Form.Label>
              <Form.Select onChange={handleRoleChange} value={role}>
                {roles.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Button variant="info" onClick={handleSubmit}>
                Guardar
              </Button>
              <Button
                variant="danger"
                onClick={() => navigate(`/residence-details/${user.residence}`)}
              >
                Cancelar
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Col>
    );
  }
}

export default EditUser;
