import React from "react";
import Col from "react-bootstrap/Col";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChartElement({ title, answers }) {
  let labelsArray = [];
  let frequencyArray = [];
  let colorsArray = [];

  answers.forEach((element) => {
    labelsArray.push(element.name);
    frequencyArray.push(element.frequency);
    colorsArray.push(element.color);
  });

  let data = {
    labels: labelsArray,
    datasets: [
      {
        label: title,
        data: frequencyArray,
        backgroundColor: colorsArray,
      },
    ],
  };

  return (
    <Col className="pie-chart-element">
      <Col className="pie-chart-element-header">
        <h4>{title}</h4>
      </Col>
      <Col className="pie-chart-element-content" lg={6}>
        <Pie data={data} />
      </Col>
    </Col>
  );
}

export default PieChartElement;
