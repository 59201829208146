import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Expense from "../Components/Expense";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";

function Expenses() {
  const { state } = useContext(AuthContext);
  const [expenses, setExpenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/expenses/account/${state.account}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setExpenses(response.data.expenses);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const deleteExpense = async (id) => {
    setIsLoading(true);
    await axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}/expenses/${id}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/expenses/${state.account}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setExpenses(response.data.expenses);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    if (expenses.length > 0) {
      return (
        <Col className="expenses-container">
          <Col className="expenses-header">
            <Row className="align-items-center">
              <Col lg={9}>
                <ToastContainer />
                <h4>Egresos</h4>
              </Col>
              <Col lg={3} className="right icon">
                <FaPlusCircle onClick={() => navigate(`/new-expense`)} />
              </Col>
            </Row>
          </Col>
          <Col className="expenses-content">
            {expenses.map((item) => (
              <Expense
                title={item.title}
                amount={item.amount}
                date={item.created_at}
                id={item._id}
                key={item._id}
                deleteExpense={deleteExpense}
              />
            ))}
          </Col>
        </Col>
      );
    } else {
      return (
        <Col className="expenses">
          <Col className="expenses-header">
            <Row className="align-items-center">
              <Col lg={9}>
                <ToastContainer />
                <h4>Egresos</h4>
              </Col>
              <Col lg={3} className="right icon">
                <FaPlusCircle onClick={() => navigate(`/new-expense`)} />
              </Col>
            </Row>
          </Col>
          <Col className="expenses-content center">
            <h4>No hay egresos registrados para este fraccionamiento</h4>
          </Col>
        </Col>
      );
    }
  }
}

export default Expenses;
