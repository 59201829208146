import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  FaUserPlus,
  FaDoorOpen,
  FaCommentAlt,
  FaHouseUser,
  FaUserClock,
  FaMoneyBillAlt,
  FaTruck,
  FaTree,
  FaBirthdayCake,
  FaRegMoneyBillAlt,
  FaWalking,
} from "react-icons/fa";
const dayjs = require("dayjs");

function LedgerEvent({
  firstName,
  lastName,
  created,
  type,
  visitorName,
  name,
  amount,
  residence,
  amenity,
}) {
  const selectIcon = (type) => {
    if (type === "NewUser") {
      return <FaUserPlus />;
    } else if (type === "OpenresidentesDoor") {
      return <FaDoorOpen />;
    } else if (type === "OpenvisitantesDoor") {
      return <FaDoorOpen />;
    } else if (type === "OpensalidaDoor") {
      return <FaDoorOpen />;
    } else if (type === "NewNotice") {
      return <FaCommentAlt />;
    } else if (type === "NewSignup") {
      return <FaHouseUser />;
    } else if (type === "NewVisitorSignup") {
      return <FaUserClock />;
    } else if (type === "ManualPaymentApproved") {
      return <FaMoneyBillAlt />;
    } else if (type === "NewPublicService") {
      return <FaTruck />;
    } else if (type === "NewAmenity") {
      return <FaTree />;
    } else if (type === "NewEvent") {
      return <FaBirthdayCake />;
    } else if (type === "NewExpense") {
      return <FaRegMoneyBillAlt />;
    } else if (type === "PublicServiceVisit") {
      return <FaTruck />;
    } else if (type === "OpenWalkingDoor") {
      return <FaWalking />;
    } else if (type === "OpenpeatonalDoor") {
      return <FaWalking />;
    }
  };
  const selectHelperText = (type) => {
    if (type === "NewUser") {
      return <p className="helper-text">Nuevo usuario</p>;
    } else if (type === "OpenresidentesDoor") {
      return <p className="helper-text">Abrió puerta residentes</p>;
    } else if (type === "OpenvisitantesDoor") {
      return <p className="helper-text">Abrió puerta visitantes</p>;
    } else if (type === "OpensalidaDoor") {
      return <p className="helper-text">Abrió puerta salida</p>;
    } else if (type === "NewNotice") {
      return <p className="helper-text">Creó nuevo aviso</p>;
    } else if (type === "NewSignup") {
      return <p className="helper-text">Se registró</p>;
    } else if (type === "NewVisitorSignup") {
      return <p className="helper-text">Registró nuevo visitante</p>;
    } else if (type === "ManualPaymentApproved") {
      return <p className="helper-text">Registró pago manual</p>;
    } else if (type === "NewPublicService") {
      return <p className="helper-text">Creó nuevo servicio</p>;
    } else if (type === "NewAmenity") {
      return <p className="helper-text">Creó nueva amenidad</p>;
    } else if (type === "NewEvent") {
      return <p className="helper-text">Creó un evento</p>;
    } else if (type === "NewExpense") {
      return <p className="helper-text">Registró un gasto</p>;
    } else if (type === "PublicServiceVisit") {
      return <p className="helper-text">Ingresó servicio público</p>;
    } else if (type === "OpenWalkingDoor") {
      return <p className="helper-text">Abrió puerta peatonal</p>;
    } else if (type === "OpenpeatonalDoor") {
      return <p className="helper-text">Abrió puerta peatonal</p>;
    }
  };

  const selectAction = (type) => {
    if (type === "NewVisitorSignup") {
      return <p className="key-text">{visitorName}</p>;
    } else if (type === "ManualPaymentApproved") {
      return <p className="key-text">{residence}</p>;
    } else if (type === "NewPublicService") {
      return <p className="key-text">{name}</p>;
    } else if (type === "NewAmenity") {
      return <p className="key-text">{name}</p>;
    } else if (type === "NewEvent") {
      return <p className="key-text">{amenity}</p>;
    } else if (type === "NewExpense") {
      return <p className="key-text">${amount}</p>;
    } else if (type === "OpenvisitantesDoor") {
      return <p className="key-text">{visitorName}</p>;
    } else if (type === "PublicServiceVisit") {
      return <p className="key-text">{visitorName}</p>;
    } else {
      return <></>;
    }
  };

  return (
    <Col className="ledger-event">
      <Row className="align-items-center">
        <Col lg={1} className="center">
          {selectIcon(type)}
        </Col>
        <Col lg={8} style={{ textAlign: "start" }}>
          <h6>
            {firstName} {lastName}
          </h6>
          <p className="residence">{residence}</p>
          {selectAction(type)}
          {selectHelperText(type)}
        </Col>
        <Col lg={3} style={{ textAlign: "end" }}>
          <p className="dateTime">{dayjs(created).format("MMM DD")}</p>
          <p className="dateTime">{dayjs(created).format("HH:mm")}</p>
        </Col>
      </Row>
    </Col>
  );
}

export default LedgerEvent;
