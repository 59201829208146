import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaTrashAlt } from "react-icons/fa";
const dayjs = require("dayjs");

function Notice({ id, date, message, deleteNotice }) {
  return (
    <Col className="notice-element mb-2">
      <Col>
        <Row className="align-items-center">
          <Col lg={11} className="notice-date">
            {dayjs(date).format("DD-MMM-YYYY")}
          </Col>
          <Col lg={1} className="right delete-icon">
            <FaTrashAlt onClick={() => deleteNotice(id)} />
          </Col>
        </Row>
      </Col>
      <Col lg={11} className="notice-message">
        {message}
      </Col>
    </Col>
  );
}

export default Notice;
