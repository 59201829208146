import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import AuthContext from "../../Context/AuthContext";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const { signout } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSignOut = () => {
    navigate("/");
    signout();
  };

  return (
    <Navbar className="navbar-safehut">
      <Container>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="logoutText" onClick={() => handleSignOut()}>
            Salir
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
