import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";

function NewExpense() {
  const [amount, setAmount] = useState(0);
  const [title, setTitle] = useState("");

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const handleAmountChange = (evt) => {
    setAmount(evt.target.value);
  };

  const handleTitleChange = (evt) => {
    setTitle(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/expenses/`,
        data: {
          title: title,
          amount: amount,
          account: state.account,
          approver: state.userId,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate(`/expenses`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Col className="new-expense">
      <Col>
        <h4>Nuevo egreso</h4>
      </Col>
      <Col className="new-expense-form">
        <Form>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Titulo"
              onChange={handleTitleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="number"
              placeholder="Cantidad"
              onChange={handleAmountChange}
            />
          </Form.Group>
          <Form.Group>
            <Button variant="info" onClick={handleSubmit}>
              Guardar
            </Button>
            <Button variant="danger" onClick={() => navigate(`/expenses`)}>
              Cancelar
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Col>
  );
}

export default NewExpense;
