import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Residence from "../Components/Residence";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Residences() {
  const { state } = useContext(AuthContext);
  const [residences, setResidences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/residences/accountResidences/${state.account}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setResidences(response.data.residences);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="residences">
        <Col className="residences-header">
          <Row className="align-items-center">
            <Col lg={9}>
              <h4>Residencias</h4>
            </Col>
            <Col lg={3} className="right icon">
              <FaPlusCircle onClick={() => navigate("/new-residence")} />
            </Col>
          </Row>
        </Col>
        <Col className="residences-content">
          {residences.map((item) => (
            <Residence address={item.address} id={item._id} key={item._id} />
          ))}
        </Col>
      </Col>
    );
  }
}

export default Residences;
