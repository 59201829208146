import React from "react";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Logo from "../../Shared/Images/SafehutLogoBlack.png";
import { FaArrowAltCircleLeft } from "react-icons/fa";

function Success() {
  return (
    <Col lg={8} className="success center">
      <Col className="success-container">
        <Image fluid src={Logo} />
        <h2>Tu solicitúd de soporte ha sido enviada</h2>
        <h5>Recibirás respuesta a tus datos de contacto</h5>
        <NavLink to="/support">
          <p>
            <FaArrowAltCircleLeft /> Regresar
          </p>
        </NavLink>
      </Col>
    </Col>
  );
}

export default Success;
