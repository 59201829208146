import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
const dayjs = require("dayjs");

function FinanceReport() {
  const [report, setReport] = useState();
  const [revenue, setRevenue] = useState();
  const [expense, setExpense] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/finance/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setReport(result.data.report);
      setExpense(result.data.expense);
      setRevenue(result.data.revenue);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="finance-report">
        <h4>Ingresos</h4>
        <Col className="revenue">
          <Row className="align-items-center">
            <Col>
              <Col>
                <FaArrowCircleUp />
              </Col>
              <Col>
                <p className="revenue-date">
                  {dayjs(report.start_date).format("MMM DD")}
                </p>
              </Col>
            </Col>
            <Col className="right">
              <Col>
                <p className="revenue-amount">${revenue}</p>
              </Col>
              <Col>
                <p className="revenue-date">
                  {dayjs(report.end_date).format("MMM DD")}
                </p>
              </Col>
            </Col>
          </Row>
        </Col>

        <h4>Egresos</h4>

        <Col className="expenses">
          <Row className="align-items-center">
            <Col>
              <Col>
                <FaArrowCircleDown />
              </Col>
              <Col>
                <p className="expense-date">
                  {dayjs(report.start_date).format("MMM DD")}
                </p>
              </Col>
            </Col>
            <Col className="right">
              <Col>
                <p className="expense-amount">${expense}</p>
              </Col>
              <Col>
                <p className="expense-date">
                  {dayjs(report.end_date).format("MMM DD")}
                </p>
              </Col>
            </Col>
          </Row>
        </Col>
        <h4>Total</h4>
        <Col className={revenue - expense > 0 ? "revenue" : "expenses"}>
          <Row className="align-items-center">
            <Col>
              <Col>
                {revenue - expense > 0 ? (
                  <FaArrowCircleUp />
                ) : (
                  <FaArrowCircleDown />
                )}
              </Col>
              <Col>
                <p
                  className={
                    revenue - expense > 0 ? "revenue-date" : "expense-date"
                  }
                >
                  {dayjs(report.start_date).format("MMM DD")}
                </p>
              </Col>
            </Col>
            <Col className="right">
              <Col>
                <p
                  className={
                    revenue - expense > 0 ? "revenue-amount" : "expense-amount"
                  }
                >
                  ${revenue - expense}
                </p>
              </Col>
              <Col>
                <p
                  className={
                    revenue - expense > 0 ? "revenue-date" : "expense-date"
                  }
                >
                  {dayjs(report.end_date).format("MMM DD")}
                </p>
              </Col>
            </Col>
          </Row>
        </Col>
      </Col>
    );
  }
}

export default FinanceReport;
