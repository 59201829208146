import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

function TicketDetails() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [evidencePath, setEvidencePath] = useState("");
  const [technicians, setTechnicians] = useState([]);
  const [technician, setTechnician] = useState("");
  const [supportClosed, setSupportClosed] = useState(false);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  let { tid } = useParams();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/technicians/assign`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/${tid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTitle(result.data.ticket.title);
      setDescription(result.data.ticket.description);
      setStatus(result.data.ticket.status);
      setEvidencePath(result.data.ticket.evidencePath);
      setSupportClosed(result.data.ticket.supportClosed);
      setTechnicians(response.data.users);
      setTechnician(result.data.ticket.supportUser);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleTechnicianChange = async (evt) => {
    setTechnician(evt.target.value);
  };

  const handleDownloadEvidence = async (key) => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/tickets/download/evidence`,
      data: { key: key },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");

    link.download = key;

    link.href = response.data.url;

    link.target = "_blank";

    link.click();
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/assign/technician`,
        data: {
          ticketId: tid,
          technicianId: technician,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/tickets");
    } catch (error) {
      console.log(error);
    }
  };

  const handleTechnicianRepair = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/close/support/${tid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/tickets");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseTicket = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/tickets/close/user/${tid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/tickets");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  } else {
    return (
      <Col className="new-ticket">
        <Col>
          <h4>Detalles del ticket</h4>
        </Col>
        {supportClosed && status === "open" ? (
          <Col>
            <Alert variant="success">Reparado por tecnico</Alert>
          </Col>
        ) : (
          <></>
        )}

        <Col className="new-ticket-form">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Título del ticket"
                value={title}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Descripción del ticket"
                value={description}
                disabled
              />
            </Form.Group>
            {state.role === "root" ? (
              <Form.Group className="mb-3">
                <Form.Label>Técnicos disponibles</Form.Label>
                <Form.Select
                  onChange={handleTechnicianChange}
                  value={technician}
                >
                  <option key={""} value={""}>
                    Seleccionar tecnico
                  </option>
                  {technicians.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : (
              <> </>
            )}
            {evidencePath !== "" ? (
              <p
                className="evidence"
                onClick={() => handleDownloadEvidence(evidencePath)}
              >
                Descargar referencia
              </p>
            ) : (
              <></>
            )}
            <Button variant="secondary" onClick={() => navigate("/tickets")}>
              Regresar
            </Button>
            {state.role === "root" &&
            (status === "open" || status === "pending") ? (
              <Button variant="primary" onClick={() => handleSubmit()}>
                Asignar técnico
              </Button>
            ) : state.role === "support" && supportClosed === false ? (
              <Button
                variant="primary"
                onClick={() => handleTechnicianRepair()}
              >
                Confimar reparación
              </Button>
            ) : state.role === "admin" &&
              (status === "open" || status === "pending") ? (
              <Button variant="primary" onClick={() => handleCloseTicket()}>
                Cerrar ticket
              </Button>
            ) : (
              <></>
            )}
          </Form>
        </Col>
      </Col>
    );
  }
}

export default TicketDetails;
