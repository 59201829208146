import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Account({ name, id, changeAccount }) {
  const navigate = useNavigate();

  return (
    <Col className="account-element">
      <Row>
        <Col lg={6}>
          <h5
            onClick={() =>
              changeAccount(id, () => toast(`${name} seleccionado`))
            }
          >
            {name}
          </h5>
        </Col>
        <Col lg={6} className="right">
          <Button
            size="sm"
            onClick={() => navigate(`/edit-account/${id}`)}
            variant="info"
            style={{ marginRight: 10 }}
          >
            Editar nombre
          </Button>
          <Button
            size="sm"
            onClick={() => navigate(`/edit-account-payout/${id}`)}
            variant="primary"
            style={{ marginRight: 10 }}
          >
            Editar cuota
          </Button>
          <Button
            size="sm"
            onClick={() => navigate(`/delete-account/${id}`)}
            variant="danger"
          >
            Eliminar
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default Account;
